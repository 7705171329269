import Head from 'next/head'

export default function Home() {

  return (
    <Head>
      <title>HODLVERSE</title>
    </Head>
  )
}
